import { FC } from "react";
import classNames from "classnames";
import { StoreShipmentInterface } from "../../interfaces/Dtos";
import {
  formatName,
  currencyExchangeText,
  useCurrencyExchanges,
  shipmentStatusFormat,
  paymentModeFormat,
  documentStatusFormat,
} from "../../utils";
import { useAppSelector } from "../../store/hooks";
import { DocumentType, ShipmentStatus } from "../../interfaces";
import { useNavigate } from "react-router-dom";

interface ShipmentItemProps {
  index: number;
  shipment: StoreShipmentInterface;
}

const ShipmentItem: FC<ShipmentItemProps> = ({ index, shipment }) => {
  const navigate = useNavigate();
  const taxIdentificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );
  const exchanges = useCurrencyExchanges();

  return (
    <tr
      className={classNames(
        "cursor-pointer hover:bg-gray-100",
        index % 2 === 0 && "bg-gray-50"
      )}
      onClick={() => navigate(`/shipments/${shipment.shipmentNumber}`)}
    >
      <td className="text-xs px-4 py-4 font-bold text-gray-500 truncate text-left">
        <p className="text-indigo-600">{shipment.shipmentNumber}</p>
        <p>
          <span className="text-gray-400">Creada: </span>{" "}
          {new Date(shipment.creationDate)
            .toLocaleDateString("es-VE", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            })
            .replace("a", "AM")
            .replace("p", "PM")
            .slice(0, -4)}
        </p>
        <p>{paymentModeFormat(shipment.paymentModeID)}</p>
        <p className="">{shipmentStatusFormat(shipment.statusID)}</p>
      </td>

      <td className="text-xs px-4 py-4 font-bold text-gray-500 truncate text-left truncate">
        <p>{formatName(shipment.shipperName)}</p>
        <p className="text-gray-400 font-medium">
          {
            taxIdentificationTypes.find(
              (t) =>
                t.taxIdentificationTypeId ===
                shipment.shipperTaxIdentificationTypeID
            )?.abreviationName
          }
          {shipment.shipperIdentificationNumber}
        </p>
        <p className="text-gray-400 font-medium">{shipment.shipperPhone}</p>
        <p>
          {shipment.buCodeSource} - {shipment.sourceBUAreaName}
        </p>
      </td>

      <td className="text-xs px-4 py-4 font-bold text-gray-500 truncate text-left truncate">
        <p>{formatName(shipment.consigneeName)}</p>
        <p className="text-gray-400 font-medium">
          {
            taxIdentificationTypes.find(
              (t) =>
                t.taxIdentificationTypeId ===
                shipment.consigneeTaxIdentificationTypeID
            )?.abreviationName
          }
          {shipment.consigneeIdentificationNumber}
        </p>
        <p className="text-gray-400 font-medium">{shipment.consigneePhone}</p>
        <p>
          {shipment.buCodeConsignee} - {shipment.consigneeBUAreaName}
        </p>
      </td>

      <td className="text-xs px-4 py-4 font-normal w-auto text-gray-500 truncate">
        <p>
          Entrega:{" "}
          <span className="font-bold">
            {shipment.storePickup === "1" ? "En Tienda" : "A Domicilio"}
          </span>
        </p>
        <p>
          Nº de piezas:{" "}
          <span className="font-bold">{shipment.totalPieces}</span>
        </p>
        <p>
          Peso:{" "}
          <span className="font-bold">{shipment.totalChargedWeight} Kg</span>
        </p>
      </td>

      <td className="text-left text-xs px-4 py-4 font-semibold w-auto text-gray-500 truncate">
        <p
          className={classNames(
            "font-bold",
            !shipment.documentStatusID && "hidden"
          )}
        >
          <span className="font-normal">
            {shipment.documentTypeID === DocumentType.INVOICE
              ? "Factura: "
              : "Orden: "}
          </span>
          {!!shipment.documentStatusID
            ? shipment.documentNumber
            : "Sin Factura Asociada"}
        </p>
        <p className="font-bold">
          {!!shipment.documentStatusID
            ? documentStatusFormat(shipment.documentStatusID)
            : "Sin Factura Asociada"}
        </p>

        <p className={classNames("hidden")}>
          Monto de la guía:{" "}
          <span className="font-bold">
            {currencyExchangeText(
              shipment.totalAmountBaseCurr,
              exchanges,
              "USD"
            )}
          </span>
        </p>
      </td>

      <td
        className={classNames(
          "text-left text-xs px-4 py-4 font-normal w-auto text-gray-500 truncate"
        )}
      >
        <p className="font-bold">
          {shipment.statusID === ShipmentStatus.DELIVERED
            ? "Entregada a cliente"
            : `${shipment.lastBU} - ${shipment.lbuBUAreaName}`}
        </p>
        <p
          className={classNames(
            shipment.statusID === ShipmentStatus.DELIVERED && "hidden"
          )}
        >
          Desde:{" "}
          <span className="font-bold">
            {new Date(shipment.lastBUDate)
              .toLocaleDateString("es-VE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })
              .replace("a", "AM")
              .replace("p", "PM")
              .slice(0, -4)}
          </span>
        </p>
      </td>
    </tr>
  );
};

export default ShipmentItem;
