import "./App.css";
import { FC, useEffect, useState } from "react";
import Layout from "./modules/Layout";
import Alert from "./components/Alert";
import AppRoutes from "./utils/AppRoutes";
import { Subscribe } from "@react-rxjs/core";
import { Route, Routes } from "react-router-dom";
import { SessionTimeInMilliseconds } from "./utils";
import GlobalLoader from "./components/GlobalLoader";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { AppDataInterface, CountryInterface } from "./interfaces";
import {
  getCountries,
  logoutSession,
  getBaseAppData,
  getBusinessUnits,
  getTaxIdentificationTypes,
  alertService,
} from "./services";
import {
  logout,
  setInmutableAppData,
  setInmutableCountries,
  setInmutableRateItems,
  setInmutableCurrencyList,
  setInmutablePaymentModes,
  setInmutableExchangeRate,
  setInmutableBusinessUnits,
  setInmutablePieceCategories,
  setInmutableBusinessServices,
  setInmutableTaxIdentificationTypes,
  setInmutableRangeDeclareList,
  setInmutableVolumeMin,
  setInmutableHighDeclaredValue,
  setInmutableDensityMax,
} from "./store/slices";

interface AppProps {}
const App: FC<AppProps> = () => {
  const dispatch = useAppDispatch();
  const userConnected = useAppSelector((state) => state.user.user);
  const userSession = useAppSelector((state) => state.user.userSession);
  const expirationTime = useAppSelector(
    (state) => state.user.userSession?.validUntil
  );
  const userLoginStartTime = useAppSelector(
    (state) => state.user.userLoginStartTime
  );
  const [remainingTime, setRemainingTime] = useState(SessionTimeInMilliseconds);

  const countries = useAppSelector((state) => state.inmutable.countries);
  const user = useAppSelector((state) => state.user);
  const venCountry = countries.find(
    (c: CountryInterface) => c.countryCodeIso === "VEN"
  );

  useEffect(() => {
    if (!userConnected || !userLoginStartTime) return;
    const intervalId = setInterval(() => {
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - userLoginStartTime;
      const newRemainingTime = SessionTimeInMilliseconds - elapsedTime;

      //submethod t o execute async logout
      const executeLougout = async () => {
        if (!userConnected || !userSession) return;
        const response = await logoutSession(
          userConnected.userID,
          userSession.sessionID
        );
        if (!response.didError) dispatch(logout());
      };
      if (newRemainingTime <= 0 || localStorage.getItem("Token") === "end") {
        // If remaining time is less than 0, logout
        executeLougout();
      } else {
        // Update the remaining time
        setRemainingTime(newRemainingTime);
      }
    }, 1000); // update every second

    return () => clearInterval(intervalId);
  }, [
    remainingTime,
    setRemainingTime,
    userConnected,
    dispatch,
    userLoginStartTime,
    userSession,
    expirationTime,
  ]);

  // Fetch countries and set them in the store
  useEffect(() => {
    const findCountries = async () => {
      const countries = await getCountries();
      if (!!countries) {
        dispatch(setInmutableCountries(countries));
      }
    };

    findCountries();
  }, [dispatch]);

  // Fetch app data and set them in the store
  useEffect(() => {
    const getAppData = async () => {
      const response = await getBaseAppData(venCountry?.id || null);

      if (!response.didError && !!response.model) {
        const appDataDto = response.model;
        // Set App Data
        dispatch(
          setInmutableAppData({
            applicationName: appDataDto.applicationName,
            applicationID: appDataDto.applicationID,
            applicationDescription: appDataDto.applicationDescription,
            applicationCode: appDataDto.applicationCode,
          } as AppDataInterface)
        );
        dispatch(setInmutableVolumeMin(appDataDto.volumenMin?.[0]));
        dispatch(setInmutableDensityMax(appDataDto.densityMax?.[0]));
        dispatch(setInmutableHighDeclaredValue(appDataDto.highDeclaredValue?.[0]));

        // Set Exchange Rate
        if (!!appDataDto.exchangeRate) {
          dispatch(setInmutableExchangeRate(appDataDto.exchangeRate));
        }
        // Set Business Services
        if (!!appDataDto.businessServiceList) {
          dispatch(
            setInmutableBusinessServices(appDataDto.businessServiceList)
          );
        }
        // Set Rate Item List
        if (!!appDataDto.rateItemList) {
          dispatch(setInmutableRateItems(appDataDto.rateItemList));
        }
        // Set Piece Categories
        if (!!appDataDto.shippingCategoryList) {
          const categories = appDataDto.shippingCategoryList.map((c) => ({
            id: c.categoryID,
            name: c.categoryName,
            code: c.categoryCode,
            descriptionTags: c.descriptionTags,
          }));
          dispatch(setInmutablePieceCategories(categories));
        }
        // Set Currency List
        if (!!appDataDto.currencyList) {
          dispatch(setInmutableCurrencyList(appDataDto.currencyList));
        }
        // Set Payment Mode List
        if (!!appDataDto.paymentModeList) {
          dispatch(setInmutablePaymentModes(appDataDto.paymentModeList));
        }
        // Set Range Declare List
        if (!!appDataDto.rangeDeclareList) {
          dispatch(setInmutableRangeDeclareList(appDataDto.rangeDeclareList));
        }

        // TODO add the rest of inmutable data
        //Load exchange rate every day at determined hours?
      } else {
        alertService.error(
          "Error al cargar los datos de la aplicación",
          response.errorMessage,
          { autoClose: false }
        );
      }
    };

    getAppData();
  }, [dispatch, venCountry?.id]);

  // Fetch stores
  useEffect(() => {
    const getAllBusinessUnits = async () => {
      const businessUnits = await getBusinessUnits();

      if (!!businessUnits) {
        dispatch(setInmutableBusinessUnits(businessUnits));
      }
    };

    getAllBusinessUnits();
  }, [dispatch]);

  useEffect(() => {
    const findTaxIdentificationTypes = async () => {
      const taxIdTypes = await getTaxIdentificationTypes();
      if (!!taxIdTypes) {
        dispatch(setInmutableTaxIdentificationTypes(taxIdTypes));
      }
    };

    findTaxIdentificationTypes();
  }, [dispatch]);

  return (
    <Subscribe>
      {!!userConnected && <Layout />}

      <Routes>
        {AppRoutes.filter(
          (r) =>
            r.auth === !!userConnected &&
            (!r.rolesRequired ||
              (!!userConnected &&
                r.rolesRequired?.includes(userConnected.roleName)))
        ).map((route, index) => {
          const { element, ...rest } = route;
          return <Route key={index} {...rest} element={element} />;
        })}
      </Routes>

      <Alert />
      <GlobalLoader />
    </Subscribe>
  );
};
export default App;
