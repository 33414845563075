import { Nullable } from "./GenericTypes";
import { ShipmentService } from "./ShipmentInterface";

export interface AccountPhoneInterface extends AccountPhoneRequestInterface {
  phoneID: string;
  accountID: string;
  phoneTypeID: number;
}

export interface AccountPhoneRequestInterface {
  phoneID: Nullable<string>;
  accountID: Nullable<string>;
  countryID: number;
  phoneNumber: string;
  countryPhoneAccessCode: Nullable<string>;
}
export interface AccountEmailRequestInterface {
  emailID: Nullable<string>;
  accountID: Nullable<string>;
  email: string;
  emailTypeID: number;
}
export interface AccountEmailInterface extends AccountEmailRequestInterface {
  emailID: string;
  accountID: string;
}

export enum SalesFeeStatus {
  RESERVED = 1,
  COMMISSIONING = 2,
  EXPIRED = 3,
  AVAILABLE = 4,
}

export interface BUSellerConfig {
  buSellerConfigID: number;
  buSeller?: string;
  accountID: string;
  reservationValidUntil: string;
  salesFeeStartDate?: string;
  salesFeeEndDate?: string;
  salesFeeStatusID: SalesFeeStatus;
  salesFeeStatusDate: string;
  creationUser: string;
  creationDate: string;
  updateUser?: string;
  updateDate?: string;
  statusID: number;
}

export interface AccountInterface extends AccountRequestInterface {
  id: string;
  accountCode: string;
  serviceID?: ShipmentService;
  listAccountPhone: AccountPhoneInterface[];
  listAccountEmail: AccountEmailInterface[];
  agreementID?: Nullable<string>;
  accountSiteID?: Nullable<number>;
  acountBillTo?: Nullable<string>;
  accountSiteName?: Nullable<string>;
  accountSiteCode?: Nullable<string>;
  buSeller?: BUSellerConfig;
}

export interface AccountRequestInterface {
  id: Nullable<string>;
  accountTypeID?: number;
  fiscalAddress?: string;
  fiscalPhoneNumber?: string;
  countryID?: number;
  accountCode: Nullable<string>;
  taxIdentificationTypeID: Nullable<number>;
  taxIdentificationTypeCode: Nullable<string>;
  abreviationName: string;
  identificationNumber: string;
  accountFullName: string;
  listAccountPhone: AccountPhoneRequestInterface[];
  listAccountEmail: AccountEmailRequestInterface[];
  listAuthorizingAccount: AccountInterface[];
  creationUser?: Nullable<string>;
  creationDate?: string;
}
