import { FC, useEffect, useMemo, useState } from "react";
import { AccountSearch } from "../components/Account/AccountSearch";
import { useAppSelector } from "../store/hooks";
import { VEN_CODE, CI_CODE } from "../components/Account/CreateClientModal";
import { VEN_ID } from "../components/Account/UpdateClientModal";
import {
  AccountInterface,
  SalesFeeStatus,
} from "../interfaces/AccountInterface";
import AccountFormPage, {
  emptyPhonePrefix,
  phonePrefixOptions,
} from "../components/Account/AccountFormPage";
import HorizontalPadding from "../components/HorizontalPadding";
import {
  LinkText,
  PrimaryButton,
  SecondaryButton,
} from "../components/Buttons";
import {
  alertService,
  getAccountHistoricalShipments,
  loaderService,
  reserveBUSeller,
} from "../services";
import { formatSalesFeeStatus } from "../utils";
import classNames from "classnames";
import Modal from "../components/Modal";
import { StoreShipmentInterface } from "../interfaces/Dtos";
import ShipmentTable from "./Clients/ShipmentTable";
import LoadingIcon from "../components/LodingIcon";
import { FormTextInput } from "../components/FormFields";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import {
  MixedFilter,
  MixedFilterDisclosure,
} from "../components/FilterDisclosure";

enum SearchBy {
  SHIPPER = "Remitente",
  CONSIGNEE = "Destinatario",
  ACCOUNT_BILL_TO = "Factura a nombre de",
}
const INITIAL_SEARCH_BY = [
  SearchBy.SHIPPER,
  SearchBy.CONSIGNEE,
  SearchBy.ACCOUNT_BILL_TO,
].map((searchBy) => ({
  name: searchBy as string,
  value: searchBy,
  checked: true,
}));

const Clients: FC = () => {
  const loading = loaderService.useIsLoading();
  const user = useAppSelector((state) => state.user);
  const businessUnits = useAppSelector(
    (state) => state.inmutable.businessUnits
  );

  const [client, setClient] = useState<AccountInterface>();
  const [shipmentSearch, setShipmentSearch] = useState("");
  const [searchBy, setSearchBy] = useState(INITIAL_SEARCH_BY);
  const [loadingShipments, setLoadingShipments] = useState(false);
  const [openProspectModal, setOpenProspectModal] = useState(false);
  const [openCreateClientForm, setOpenCreateClientForm] = useState(false);
  const [openUpdateClientForm, setOpenUpdateClientForm] =
    useState<Boolean>(false);
  const [clientShipments, setClientShipments] = useState<
    StoreShipmentInterface[]
  >([]);

  useEffect(() => {
    if (!!client) {
      setOpenCreateClientForm(false);
      setOpenUpdateClientForm(false);
    }
  }, [client]);

  const countries = useAppSelector((state) => state.inmutable.countries);
  const taxIdentificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );

  const countryId = useMemo(
    () =>
      !!client?.listAccountPhone
        ? client.listAccountPhone[0]?.countryID
        : VEN_ID,
    [client]
  );

  const countryId2 = useMemo(
    () =>
      !!client?.listAccountPhone[1]
        ? client.listAccountPhone[1]?.countryID
        : VEN_ID,
    [client]
  );

  const isEnterprise = useMemo(() => {
    return (
      !!client?.agreementID || (client?.listAuthorizingAccount?.length ?? 0) > 0
    );
  }, [client]);

  const isReservable = useMemo(() => {
    return (
      !!client &&
      (user.user?.roleName === "Superadministrador" ||
        user.user?.roleName === "Administrador" ||
        user.user?.roleName === "Supervisor") &&
      !client.buSeller
    );
  }, [client, user.user?.roleName]);

  const editable = useMemo(() => {
    if (!!isEnterprise && user.user?.roleName === "Superadministrador") {
      return true;
    }
    if (!!isEnterprise) {
      return false;
    } else {
      return true;
    }
  }, [isEnterprise, user.user?.roleName]);

  const isProspectable = useMemo(() => {
    return (
      !!client?.buSeller &&
      client.buSeller.buSeller === user.businessUnit?.code &&
      (client?.abreviationName === "J-" || client?.abreviationName === "G-")
    );
  }, [client, user.businessUnit?.code]);

  const clientInfo = useMemo(() => {
    const getPhoneNumber = (phoneNumber: string, countryId: number) => {
      // Delete all chars that are not numbers
      phoneNumber = phoneNumber.replace(/\D/g, "");

      const prefix = getPhonePrefix(phoneNumber, countryId).value;
      const result = phoneNumber.slice(prefix.length, phoneNumber.length);

      return result;
    };

    const getPhonePrefix = (phoneNumber: string, countryId: number) => {
      // Delete all chars that are not numbers
      phoneNumber = phoneNumber.replace(/\D/g, "");

      if (countryId === VEN_ID) {
        const prefix = phoneNumber.slice(0, 3);
        const option = phonePrefixOptions.find((p) => p.value === prefix);
        if (!!option) return option;
      }

      return emptyPhonePrefix;
    };

    return {
      fullName: client?.accountFullName ?? "",
      email: !!client?.listAccountEmail?.[0]
        ? client.listAccountEmail[0]?.email
        : "",
      address: client?.fiscalAddress ?? "",
      // First phone
      country:
        countries.find((c) => c.id === countryId) ??
        countries.find((c) => c.id === VEN_ID)!,
      phonePrefix: !!client?.listAccountPhone?.[0]
        ? getPhonePrefix(client.listAccountPhone[0].phoneNumber, countryId)
        : emptyPhonePrefix,
      phone: !!client?.listAccountPhone?.[0]
        ? getPhoneNumber(client.listAccountPhone[0].phoneNumber, countryId)
        : "",

      // Second phone
      country2: countries.find((c) => c.id === countryId2),
      phonePrefix2: !!client?.listAccountPhone?.[1]
        ? getPhonePrefix(client.listAccountPhone[1].phoneNumber, countryId2)
        : phonePrefixOptions.find((o) => o.value === "412")!,
      phone2: !!client?.listAccountPhone?.[1]
        ? getPhoneNumber(client.listAccountPhone[1].phoneNumber, countryId2)
        : undefined,

      idType: taxIdentificationTypes.find(
        (idType) => client?.abreviationName === idType.abreviationName
      )!,
      idNumber: client?.identificationNumber ?? "-1",

      // bu seller
      reserved: client === user.businessUnit?.code,
      buSeller: businessUnits.find(
        (bu) => bu.code === client?.buSeller?.buSeller
      ),
    };
  }, [
    client,
    countries,
    countryId,
    countryId2,
    businessUnits,
    taxIdentificationTypes,
    user.businessUnit?.code,
  ]);

  const filters = useMemo(() => {
    const result: MixedFilter<any>[] = [];

    result.push({
      title: "Buscar por",
      isMultiple: true,
      multipleOptions: searchBy,
      onSelectMultipleOption: setSearchBy,
    });

    return result;
  }, [searchBy]);

  const handleClientReserve = async () => {
    if (!client || !user.businessUnit?.code || !user.user?.userLogin) {
      return;
    }

    loaderService.start();
    const response = await reserveBUSeller(
      user.businessUnit.code,
      client.id,
      user.user.userLogin
    );
    loaderService.stop();

    if (response.didError) {
      alertService.error(
        "Hubo un error al reservar el cliente",
        response.errorMessage
      );
    } else {
      alertService.success("Cliente reservado con éxito");
      setClient({
        ...client,
        buSeller: response.model ?? undefined,
      });
    }
  };

  const formatDate = (date?: string) => {
    if (!date) return "";

    const dateObj = new Date(date);
    return `${dateObj.getDate()}/${
      dateObj.getMonth() + 1
    }/${dateObj.getFullYear()}`;
  };

  const handleSearch = async (search: string) => {
    if (!client) return;

    setLoadingShipments(true);
    const response = await getAccountHistoricalShipments(
      client.id,
      search,
      !!searchBy.find((s) => s.value === SearchBy.SHIPPER)?.checked,
      !!searchBy.find((s) => s.value === SearchBy.CONSIGNEE)?.checked,
      !!searchBy.find((s) => s.value === SearchBy.ACCOUNT_BILL_TO)?.checked
    );
    setLoadingShipments(false);

    if (response.didError || !response.model) {
      return;
    }

    setClientShipments(response.model);
  };

  useEffect(() => {
    if (!client) return;

    const afunction = async () => {
      setLoadingShipments(true);
      const response = await getAccountHistoricalShipments(
        client.id,
        "",
        true,
        true,
        true
      );
      setLoadingShipments(false);

      if (response.didError || !response.model) {
        return;
      }

      setClientShipments(response.model);
    };

    afunction();
  }, [client]);

  return (
    <main className="lg:pl-72">
      {/* Header */}
      <div className="py-8 sm:px-6 lg:px-8 bg-white relative flex items-center justify-between h-32">
        <header className="ml-4 text-2xl font-bold text-gray-700 ">
          Clientes
        </header>
      </div>

      {/* Body */}
      <HorizontalPadding paddingTop>
        <div className="flex flex-1 flex-col bg-white gap-2 rounded-lg border px-8 pb-6 pt-4">
          <h2 className="text-2xl font-semibold text-gray-900 truncate">
            Buscar Cliente
          </h2>

          <div className="flex w-full items-center justify-center mb-6 gap-4">
            <div className="max-w-[40rem] w-full">
              <AccountSearch
                title=""
                placeholder="Cédula/RIF o Nombre..."
                onSelectClient={setClient}
                openCreationModal={setOpenCreateClientForm}
              />
            </div>
          </div>

          {/* Show Client Info */}
          {client && (
            <div className="px-4px-4 sm:px-6">
              <div className="flex flex-wrap">
                <div className="w-1/3">
                  <p className="font-light">Nombre completo:</p>
                </div>
                <div className="flex items-center w-2/3">
                  <p className="font-light px-2">
                    {clientInfo.fullName ? (
                      clientInfo.fullName
                    ) : (
                      <em>No registrado</em>
                    )}
                  </p>
                  <CheckBadgeIcon
                    title="Cliente Crédito Corporativo"
                    data-te-toggle="tooltip"
                    className={classNames(
                      "ml-2 inline h-5 w-5 text-indigo-600 group-hover:text-white",
                      !isEnterprise && "hidden"
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-1/3">
                  <p className="font-light">Correo electrónico:</p>
                </div>
                <div className="w-2/3">
                  <p className="font-light px-2">
                    {clientInfo.email ? (
                      clientInfo.email
                    ) : (
                      <em>No registrado</em>
                    )}
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-1/3">
                  <p className="font-light">Dirección fiscal:</p>
                </div>
                <div className="w-2/3">
                  <p className="font-light px-2">
                    {clientInfo.address ? (
                      clientInfo.address
                    ) : (
                      <em>No registrada</em>
                    )}
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-1/3">
                  <p className="font-light">Cédula / RIF:</p>
                </div>
                <div className="w-2/3">
                  <p className="font-light px-2">
                    {clientInfo.idType.abreviationName ? (
                      clientInfo.idType.abreviationName
                    ) : (
                      <em>No registrada</em>
                    )}
                    {clientInfo.idNumber}
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-1/3">
                  <p className="font-light">Teléfono:</p>
                </div>
                <div className="w-2/3">
                  <p className="font-light px-2">
                    {clientInfo.phonePrefix.name ? (
                      clientInfo.phonePrefix.name
                    ) : (
                      <em>No registrado</em>
                    )}
                    {clientInfo.phone}
                  </p>
                </div>
              </div>
              {!!clientInfo.phone2 && (
                <div className="flex flex-wrap">
                  <div className="w-1/3">
                    <p className="font-light">Teléfono:</p>
                  </div>
                  <div className="w-2/3">
                    <p className="font-light px-2">
                      {clientInfo.phonePrefix2.name} {clientInfo.phone2}
                    </p>
                  </div>
                </div>
              )}

              {!openCreateClientForm && !openUpdateClientForm && (
                <div className="flex flex-shrink-0 gap-4 justify-end px-4 py-4">
                  <div hidden={!isReservable}>
                    <SecondaryButton
                      onClick={handleClientReserve}
                      className="px-6"
                      disabled={loading}
                    >
                      Reservar
                    </SecondaryButton>
                  </div>

                  <div hidden={user.user?.roleName === "Consulta Innovus"}>
                    <PrimaryButton
                      onClick={() => setOpenUpdateClientForm(true)}
                      className="px-6"
                      disabled={!editable}
                    >
                      Editar
                    </PrimaryButton>
                  </div>
                </div>
              )}

              {client?.buSeller &&
                client.buSeller.salesFeeStatusID !== SalesFeeStatus.AVAILABLE &&
                !openCreateClientForm &&
                !openUpdateClientForm && (
                  <div className="flex flex-col">
                    <hr className="my-4" />

                    <div>
                      <h3 className="font-semibold text-gray-800">
                        Comisión de venta
                      </h3>

                      <div className="flex flex-wrap">
                        <div className="w-1/3">
                          <p className="font-light">Estado:</p>
                        </div>
                        <div className="w-2/3">
                          <p className="font-light px-2">
                            {formatSalesFeeStatus(
                              client.buSeller.salesFeeStatusID
                            )}
                          </p>
                        </div>
                      </div>

                      <div
                        className="flex flex-wrap"
                        hidden={
                          client.buSeller.salesFeeStatusID !==
                          SalesFeeStatus.EXPIRED
                        }
                      >
                        <div className="w-1/3">
                          <p className="font-light">Vendedor:</p>
                        </div>
                        <div className="w-2/3">
                          <p className="font-light px-2">
                            {clientInfo.buSeller
                              ? `${clientInfo.buSeller.code} - ${clientInfo.buSeller.location.name}`
                              : client.buSeller.buSeller}
                          </p>
                        </div>
                      </div>

                      <div
                        className={classNames(
                          "flex flex-wrap",
                          client.buSeller.salesFeeStatusID !==
                            SalesFeeStatus.RESERVED && "hidden"
                        )}
                      >
                        <div className="w-1/3">
                          <p className="font-light">Fecha de reserva:</p>
                        </div>
                        <div className="w-2/3">
                          <p className="font-light px-2">
                            {formatDate(client.buSeller.creationDate)} -{" "}
                            {formatDate(client.buSeller.reservationValidUntil)}
                          </p>
                        </div>
                      </div>

                      <div
                        className={classNames(
                          "flex flex-wrap",
                          client.buSeller.salesFeeStatusID !==
                            SalesFeeStatus.COMMISSIONING && "hidden"
                        )}
                      >
                        <div className="w-1/3">
                          <p className="font-light">
                            Fecha comisionando venta:
                          </p>
                        </div>
                        <div className="w-2/3">
                          <p className="font-light px-2">
                            {formatDate(client.buSeller.salesFeeStartDate)} -{" "}
                            {formatDate(client.buSeller.salesFeeEndDate)}
                          </p>
                        </div>
                      </div>

                      <div
                        className={classNames(
                          "flex flex-wrap",
                          client.buSeller.salesFeeStatusID !==
                            SalesFeeStatus.EXPIRED && "hidden"
                        )}
                      >
                        <div className="w-1/3">
                          <p className="font-light">
                            Fecha desde comisión solo para origen y destino:
                          </p>
                        </div>
                        <div className="w-2/3">
                          <p className="font-light px-2">
                            {formatDate(client.buSeller.salesFeeEndDate)}
                          </p>
                        </div>
                      </div>
                    </div>

                    {isProspectable && (
                      <div className="text-sm mt-4">
                        <p className="font-light">
                          Este cliente aplica para prospección como corporativo.
                        </p>
                        <LinkText
                          text="Mostrar requisitos"
                          onClick={() => setOpenProspectModal(true)}
                        />
                      </div>
                    )}
                  </div>
                )}

              <hr className="my-8" />

              <div
                className={classNames(
                  (openCreateClientForm || openUpdateClientForm) && "hidden"
                )}
              >
                <h3 className="font-semibold text-gray-800 mb-2">
                  Histórico de envíos
                </h3>

                <div className="flex items-center gap-4 w-full mb-6">
                  <FormTextInput
                    value={shipmentSearch}
                    id="client-shipments-filter"
                    onChange={(e) => setShipmentSearch(e.target.value)}
                    placeholder="Filtrar por número de guía o por nombre/documento de remitente o destinatario"
                    data-te-toggle="tooltip"
                    title="Filtrar por número de guía o por nombre/documento de remitente o destinatario"
                  />

                  <div className="flex flex-col items-end justify-end">
                    <MixedFilterDisclosure
                      showCount
                      showClear
                      title="Filtros"
                      filters={filters}
                      className="p-0"
                    />
                  </div>

                  <PrimaryButton
                    className="w-20"
                    onClick={() => handleSearch(shipmentSearch)}
                  >
                    Buscar
                  </PrimaryButton>
                </div>

                <div
                  className={classNames(
                    (loadingShipments || clientShipments.length === 0) &&
                      "hidden"
                  )}
                >
                  <ShipmentTable shipments={clientShipments} />
                </div>
              </div>

              <div
                className={classNames(
                  "flex flex-col items-center mt-6",
                  !loadingShipments && "hidden"
                )}
              >
                <LoadingIcon size="2rem" />
                <p className="font-light">Cargando histórico de envíos...</p>
              </div>

              <div
                className={classNames(
                  "flex flex-col items-center mt-6",
                  (loadingShipments || clientShipments.length > 0) && "hidden"
                )}
              >
                <p className="font-light">No se encontraron envíos</p>
              </div>
            </div>
          )}

          {/* Create Client */}
          {openCreateClientForm && (
            <AccountFormPage
              initialValues={
                // formik initial values
                {
                  fullName: "",
                  email: "",
                  address: "",
                  country: countries.find(
                    (c) => c.countryCodeIso === VEN_CODE
                  )!,
                  phonePrefix: phonePrefixOptions.find(
                    (o) => o.value === "412"
                  )!,
                  phone: "",
                  country2: countries.find(
                    (c) => c.countryCodeIso === VEN_CODE
                  )!,
                  phonePrefix2: phonePrefixOptions.find(
                    (o) => o.value === "412"
                  )!,
                  phone2: "",
                  idType: taxIdentificationTypes.find(
                    (t) => t.taxIdentificationTypeCode === CI_CODE
                  )!,
                  idNumber: "",
                  reserved: true,
                }
              }
              setSelectedItem={setClient}
              setOpenForm={setOpenCreateClientForm}
            />
          )}

          {/* Update Client */}
          {client && openUpdateClientForm && (
            <AccountFormPage
              isUpdate
              ignoreTouched
              initialValues={clientInfo}
              accountItem={client}
              setSelectedItem={setClient}
              disableAllInputs={!editable}
              setOpenForm={setOpenUpdateClientForm}
            />
          )}
        </div>
      </HorizontalPadding>

      <Modal openModal={openProspectModal} setOpenModal={setOpenProspectModal}>
        <div className="max-w-[35rem]">
          Para prospectar como corporativo por favor envíe al correo de su
          ejecutivo de cuenta los siguientes requisitos:
          <br /> <br />
          <ul className="text-sm list-disc list-outside ml-6 font-light">
            <li>Acta Constitutiva de la empresa.</li>
            <li>
              Acta de Asamblea o Poder Notariado donde se designen las personas
              capaces de obligar a la empresa a la fecha de contratación.
            </li>
            <li>
              Acta de Asamblea en la que conste el domicilio establecido en el
              RIF de la empresa (si es la misma del Acta Constitutiva,
              notificarlo).
            </li>
            <li>
              Documento de Identidad vigente del firmante (solo puede firmar
              aquella persona con facultades de acuerdo al documento donde se
              designen las autoridades vigentes). En caso de que el documento
              sea de una residencia y no de un nacional, debe enviarse documento
              que acredite su nacionalidad.
            </li>
            <li>
              RIF o Documento de Identificación Tributaria vigente del firmante.
            </li>
            <li>
              Cédula y RIF o Documento de Identificación Tributaria vigente de
              todos los accionistas.
            </li>
            <li>
              RIF o Documento de Identificación Tributaria vigente de la
              empresa.
            </li>
            <li>
              Planilla de solicitud del servicio (se suministra al momento de
              aceptar la Propuesta de Servicio).
            </li>
            <li>2 Referencias Comerciales.</li>
            <li>2 Referencias Bancarias.</li>
          </ul>
          <div className="flex w-full justify-center mt-6">
            <PrimaryButton
              className="w-40"
              onClick={() => setOpenProspectModal(false)}
            >
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>
    </main>
  );
};

export default Clients;
