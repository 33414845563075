import { FC, useEffect, useMemo, useState } from "react";
import Modal from "../Modal";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import {
  DocumentInterface,
  ItemInterface,
  PaymentMode,
  PaymentStatusEnum,
  ResponseInterface,
  ShipmentInterface,
  ShipmentService,
  TaxInterface,
} from "../../interfaces";
import PaymentTotal from "../Payment/PaymentTotal";
import {
  alertService,
  getShipment,
  getShipmentItems,
  getShipmentRates,
  getTier,
  loaderService,
  updateShipment,
} from "../../services";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setPickupShipments } from "../../store/slices";

interface ChangePaymentModeModalProps {
  shipments: ShipmentInterface[];
  document?: DocumentInterface;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export const ChangePaymentModeModal: FC<ChangePaymentModeModalProps> = ({
  shipments,
  document,
  open,
  setOpen,
}) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const payments = useAppSelector((state) => state.pickup.payments);
  const businessUnits = useAppSelector(
    (state) => state.inmutable.businessUnits
  );
  const applicationID = useAppSelector(
    (state) => state.inmutable.appData.applicationID
  );

  const loading = loaderService.useIsLoading();
  const [newItems, setNewItems] = useState<ItemInterface[][]>([]);

  const subTotal = useMemo(() => {
    return +newItems
      .flat()
      .reduce((acc, item) => acc + item.rate.value, 0)
      .toFixed(2);
  }, [newItems]);

  const ipostel = useMemo(() => {
    return +newItems
      .flat()
      .reduce((acc, item) => acc + item.rate.ipostel, 0)
      .toFixed(2);
  }, [newItems]);

  const iva = useMemo(() => {
    return +newItems
      .flat()
      .reduce((acc, item) => acc + item.rate.iva, 0)
      .toFixed(2);
  }, [newItems]);

  const taxes = useMemo(() => {
    const taxes: TaxInterface[] = [
      {
        name: "IVA",
        value: iva,
        type: "fixed",
      },
      {
        name: "Ipostel",
        value: ipostel,
        type: "fixed",
      },
    ];

    if (!!document?.igtfAmount) {
      taxes.push({
        name: "IGTF",
        value: document.igtfAmount,
        type: "fixed",
      });
    }

    return taxes;
  }, [document, ipostel, iva]);

  const total = useMemo(() => {
    return +(
      subTotal +
      taxes?.reduce((acc, tax) => {
        if (tax.type === "percentage") {
          return acc + subTotal * (tax.value / 100);
        }
        return acc + tax.value;
      }, 0)
    ).toFixed(2);
  }, [subTotal, taxes]);

  const remaining = useMemo(() => {
    //return document?.baseBalanceAmount ?? to do this we need to get the updated document from DB
    return +(
      total -
      payments
        .filter(
          (p) =>
            p.status !== PaymentStatusEnum.CANCELED &&
            p.status !== PaymentStatusEnum.REJECT
        )
        .reduce((acc, payment) => acc + payment.amount, 0)
    ).toFixed(2);
  }, [total, payments]);

  const changePaymentMode = async () => {
    loaderService.start();
    const updatedShipments: ResponseInterface<boolean>[] = [];
    for (let i = 0; i < shipments.length; i++) {
      const shipment = shipments[i];
      const items = newItems[i];

      const response = await updateShipment(
        {
          id: shipment.id,
          shipmentNumber: shipment.shipmentNumber,
          items,
          paymentMode: PaymentMode.DEST_BOXOFFICE_CREDIT,
        },
        true,
        false,
        user.user ?? undefined,
        applicationID
      );

      updatedShipments.push(response);
    }
    loaderService.stop();

    if (
      updatedShipments.length === 0 ||
      updatedShipments.some((s) => s.didError) ||
      updatedShipments.some((s) => !s.model)
    ) {
      alertService.error(
        "No se pudo cambiar la modalidad de pago",
        updatedShipments.find((s) => s.didError)?.errorMessage
      );
      return;
    }

    const newShipments = (
      await Promise.all(
        shipments.map((shipment) => getShipment(shipment.shipmentNumber!))
      )
    )
      .map((s) => s!)
      .map((s, index) => ({
        ...s,
        items: newItems[index],
        total: newItems[index].reduce((acc, item) => acc + item.rate.value, 0),
      }));

    dispatch(setPickupShipments(newShipments));
    setOpen(false);
  };

  useEffect(() => {
    if (!open) return;

    const getItems = async (shipment: ShipmentInterface) => {
      if (
        !shipment.totalChargedWeight ||
        !shipment.declaredValue ||
        shipment.deliveryDistance === undefined ||
        !shipment.buSource?.code ||
        !shipment.consigneeAddress?.coordinates.lat ||
        !shipment.consigneeAddress?.coordinates.lng
      ) {
        return [];
      }
      const buSource = businessUnits.find(
        (bu) => bu.code === shipment.buSource.code
      );
      const tier = await getTier({
        AccountBillToID: shipment.accountBillTo?.id,
        LatitudeShipperAddress: buSource?.location.coordinates.lat,
        LongitudeShipperAddress: buSource?.location.coordinates.lng,
        LatitudeConsigneeAddress: shipment.consigneeAddress?.coordinates.lat,
        LongitudeConsigneeAddress: shipment.consigneeAddress?.coordinates.lng,
      });
      const allItemsResponse = await getShipmentItems({
        CurrencyID: 2,
        CountryID: 236,
        Weight: shipment.totalChargedWeight,
        DeclaratedValue: Number(shipment.declaredValue),
        Distance: shipment.deliveryDistance,
        ApplicationID: applicationID,
        ShipperAccountID: shipment.shipper?.id,
        ConsigneeAccountID: shipment.consignee?.id,
        AccountBillToID: shipment.accountBillTo?.id,
        AccountSiteID: shipment.accountBillTo?.accountSiteID ?? undefined,
        ServiceID: shipment.service,
        PaymentModeID: shipment.paymentMode,
        DeliveryTypeID: shipment.deliveryType,
        SRM: !!shipment.pieces?.reduce((acc, p) => acc + p.value, 0),
        TierID: tier.model?.tierID,
        ShippingMethodID: 10,
        PackageTypeID: 10,
        SalesDate: new Date().toISOString(),
        BUCodeSource: shipment.buSource?.code,
        BUCodeConsignee: shipment.buConsignee?.code,
        IsToPickup: shipment.isToPickup,
      });

      if (allItemsResponse.didError || !allItemsResponse.model) {
        alertService.error(
          `No se pudo obtener los items de la guía ${shipment.shipmentNumber}`,
          allItemsResponse.errorMessage
        );
        return [];
      }

      const allItems = allItemsResponse.model.concat(
        shipment.items
          .filter(
            (item) => !allItemsResponse.model?.find((i) => i.id === item.id)
          )
          .map((item) => ({ ...item, mandatory: false }))
      );

      const rateResponse = await getShipmentRates(
        shipment.service ?? ShipmentService.STANDARD,
        PaymentMode.DEST_BOXOFFICE_CREDIT,
        shipment.deliveryType,
        shipment.buSource.code,
        shipment.buConsignee?.code ?? "",
        shipment.accountBillTo?.id,
        businessUnits.find((bu) => bu.code === shipment.buSource.code)!
          .location,
        shipment.consigneeAddress,
        allItems,
        shipment.pieces,
        shipment.pieces.reduce((acc, p) => acc + p.value, 0) > 0,
        new Date().toISOString(),
        applicationID,
        shipment.shipper.id
      );

      if (rateResponse.didError || !rateResponse.model) {
        alertService.error(
          `No se pudo obtener las tarifas de la guía ${shipment.shipmentNumber}`,
          rateResponse.errorMessage
        );
        return [];
      }

      return rateResponse.model.items
        .map((item) => {
          const shipmentItem = shipment.items?.find((i) => i.id === item.id);

          if (item.id !== "31") return item;
          return shipmentItem ? { ...shipmentItem, mandatory: false } : item;
        })
        .filter(
          (item) =>
            shipment.items?.some((s) => s.id === item.id) || item.id === "24"
        );
    };

    const getAllShipmentItems = async () => {
      loaderService.start();
      const items = await Promise.all(
        shipments.map((shipment) => getItems(shipment))
      );
      loaderService.stop();

      setNewItems(items);
    };

    getAllShipmentItems();
  }, [open, shipments, businessUnits, applicationID]);

  return (
    <Modal
      openModal={open}
      setOpenModal={setOpen}
      className="max-w-[50rem] min-w-[40rem]"
    >
      <p className="text-xl text-gray-900">
        Cambiar modalidad de pago a{" "}
        <span className="font-semibold">Crédito Taquilla</span>
      </p>

      <PaymentTotal
        taxes={taxes}
        subTotal={subTotal}
        remaining={remaining}
        paymentMode={PaymentMode.DEST_BOXOFFICE_CREDIT}
        total={document?.total}
        className="border-0 !px-4"
      />

      <div className="flex justify-between mt-4">
        <SecondaryButton className="w-32" onClick={() => setOpen(false)}>
          Cancelar
        </SecondaryButton>

        <PrimaryButton
          className="w-32"
          disabled={loading}
          onClick={changePaymentMode}
        >
          Cambiar
        </PrimaryButton>
      </div>
    </Modal>
  );
};
