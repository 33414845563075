import { FC, useEffect, useState } from "react";
import classNames from "classnames";
import Modal from "../../components/Modal";
import PieceForm from "../../components/Shipment/PieceForm";
import { FormSimpleRadioGroup } from "../../components/FormFields";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { PrimaryButton, SecondaryButton } from "../../components/Buttons";
import {
  ShipmentService,
  PieceInterface,
  PieceCategoryInterface,
  PaymentMode,
} from "../../interfaces";
import PieceTable, {
  PieceMenuOptions,
} from "../../components/Shipment/PieceTable";

const INITIAL_VALUES = {
  category: "",
  weight: "",
  value: "",
  height: "",
  width: "",
  length: "",
  amount: "1",
};

interface ShipmentPiecesDetailsProps {
  id: string;
  service: ShipmentService;
  paymentMode?: PaymentMode;
  pieces: PieceInterface[];
  pieceCategories: PieceCategoryInterface[];
  error?: boolean;
  typeDisabled?: boolean;
  applyMinValue?: boolean;
  chargedWeight?: number;
  physicalWeight?: number;
  dimensionalWeight?: number;
  maxSumWeight?: number;
  serviceOptions: { name: string; value: ShipmentService }[];
  setPieces: (pieces: PieceInterface[]) => void;
  setServiceType: (type: ShipmentService) => void;
}

const ShipmentPiecesDetails: FC<ShipmentPiecesDetailsProps> = ({
  id,
  pieces,
  service,
  paymentMode,
  pieceCategories,
  error,
  typeDisabled,
  applyMinValue,
  chargedWeight,
  physicalWeight,
  dimensionalWeight,
  maxSumWeight,
  serviceOptions,
  setPieces,
  setServiceType,
}) => {
  const [editIndex, setEditIndex] = useState(-1);
  const [editMode, setEditMode] = useState(false);
  const [auxType, setAuxType] = useState(service);
  const [pieceToDelete, setPieceToDelete] = useState(-1);
  const [openTypeModal, setOpenTypeModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);

  const addPiece = (values: PieceInterface, amount: number = 1) => {
    if (editMode) {
      const newPieces = [...pieces];
      newPieces[editIndex] = values;
      setPieces(newPieces);
      setEditMode(false);
    } else {
      const newPieces = [...pieces];
      for (let i = 0; i < amount; i++) {
        newPieces.push(values);
      }
      setPieces(newPieces);
    }
  };

  const deletePiece = (index: number) => {
    if (index === -1) return;

    const newPieces = [...pieces];
    newPieces.splice(index, 1);
    setPieces(newPieces);
    setPieceToDelete(-1);
  };

  const duplicatePiece = (index: number) => {
    const newPieces = [...pieces];
    newPieces.push(pieces[index]);
    setPieces(newPieces);
  };

  const handleMenuClick = (
    option: { value: PieceMenuOptions },
    index: number
  ) => {
    if (option.value === PieceMenuOptions.EDIT) {
      setEditMode(true);
      setEditIndex(index);
      setInitialValues({
        category: pieces[index].category.name,
        weight: pieces[index].weight.toFixed(2),
        value: pieces[index].value.toFixed(2),
        height: pieces[index].height.toFixed(0),
        width: pieces[index].width.toFixed(0),
        length: pieces[index].length.toFixed(0),
        amount: "1",
      });
    } else if (option.value === PieceMenuOptions.DELETE) {
      setPieceToDelete(index);
      setOpenDeleteModal(true);
    } else if (option.value === PieceMenuOptions.DUPLICATE) {
      duplicatePiece(index);
    }
  };

  const handleChangeType = (type: ShipmentService) => {
    if (pieces.length === 0) {
      setAuxType(type);
      setServiceType(type);
      return;
    }
    setAuxType(type);
    setOpenTypeModal(true);
  };

  useEffect(() => {
    if (pieces.length === 0) {
      setInitialValues({
        category: "",
        weight: "",
        value: "",
        height: "",
        width: "",
        length: "",
        amount: "1",
      });
    }
  }, [pieces]);

  return (
    <div className="flex-1 flex-col">
      <div
        className={classNames(
          "mb-8",
          (serviceOptions.length === 0 || paymentMode === undefined) && "hidden"
        )}
      >
        <h3 className="text-xl font-semibold leading-7 text-gray-900 mb-2">
          Servicio
        </h3>

        <FormSimpleRadioGroup
          horizontal
          selected={service}
          disabled={typeDisabled}
          options={serviceOptions}
          onSelectOption={handleChangeType}
          name={`${id}-piecesType1`}
          className={classNames(serviceOptions.length === 0 && "hidden")}
        />
      </div>

      <div className="flex flex-1 2xl:flex-row flex-col gap-8">
        <div className="flex flex-1 flex-col gap-10">
          <PieceForm
            type={service}
            pieces={pieces}
            editMode={editMode}
            editIndex={editIndex}
            pieceCategories={pieceCategories}
            initialValues={initialValues}
            applyMinValue={applyMinValue}
            maxSumWeight={maxSumWeight}
            onSubmit={addPiece}
            setEditMode={(v) => {
              setEditMode(v);
              if (!v) setInitialValues(INITIAL_VALUES);
            }}
          />
        </div>

        <div
          className="flex flex-col"
          style={{ flex: "1.5", minHeight: "10rem" }}
        >
          <h3 className="text-xl font-semibold leading-7 text-gray-900 mb-2">
            Piezas
          </h3>

          {pieces.length === 0 ? (
            <div className="flex flex-1 items-center justify-center">
              <p
                className={classNames(
                  "leading-5",
                  error ? "text-red-500" : "text-gray-500"
                )}
              >
                No se ha añadido ninguna pieza
              </p>
            </div>
          ) : (
            <div className="overflow-y-auto h-[23rem]">
              <PieceTable
                editable
                pieces={pieces}
                editMode={editMode}
                editIndex={editIndex}
                maxSumWeight={maxSumWeight}
                onClickPieceMenu={handleMenuClick}
              />
            </div>
          )}

          <div className="flex flex-col w-full items-end text-gray-800">
            <p className="">
              Peso a facturar:{" "}
              <span className="font-semibold">
                {chargedWeight?.toFixed(2) ?? "0.00"} Kg
              </span>
            </p>
            <p className="text-xs mt-2">
              Peso balanza:{" "}
              <span className="font-semibold">
                {physicalWeight?.toFixed(2) ?? "0.00"} Kg
              </span>
            </p>
            <p className="text-xs">
              Peso volumétrico:{" "}
              <span className="font-semibold">
                {dimensionalWeight?.toFixed(2) ?? "0.00"} Kg
              </span>
            </p>
          </div>
        </div>
      </div>

      <Modal openModal={openDeleteModal} setOpenModal={setOpenDeleteModal}>
        <div className="flex flex-col gap-4">
          <h2 className="text-2xl font-semibold leading-7 text-gray-900">
            Eliminar pieza
          </h2>

          <div className="flex flex-row gap-1">
            <p className="text-gray-500">
              ¿Está seguro que desea eliminar la pieza
            </p>
            <p className="font-semibold text-gray-900 inline">
              {" "}
              {pieceToDelete + 1}
            </p>
            <p className="text-gray-500">?</p>
          </div>

          <div className="flex flex-row gap-4 items-center justify-between">
            <SecondaryButton onClick={() => setOpenDeleteModal(false)}>
              Cancelar
            </SecondaryButton>

            <PrimaryButton
              onClick={() => {
                setOpenDeleteModal(false);
                deletePiece(pieceToDelete);
              }}
              className="px-4 py-2 rounded-md"
            >
              Eliminar
            </PrimaryButton>
          </div>
        </div>
      </Modal>

      <Modal openModal={openTypeModal} setOpenModal={setOpenTypeModal}>
        <div
          className="flex flex-col items-center justify-center"
          style={{ maxWidth: "20rem" }}
        >
          <div className="flex flex-col items-center justify-center w-full">
            <ExclamationTriangleIcon className="w-32 h-32" />
          </div>

          <p className="mt-2 text-center text-gray-900">
            ¿Está seguro que desea cambiar el tipo de envio? Esta acción
            eliminará las piezas creadas
          </p>

          <div className="mt-4 flex w-full flex-row justify-between gap-4">
            <SecondaryButton
              className="px-4"
              onClick={() => setOpenTypeModal(false)}
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              className="px-4"
              onClick={() => {
                setPieces([]);
                setServiceType(auxType);
                setOpenTypeModal(false);
              }}
            >
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ShipmentPiecesDetails;
