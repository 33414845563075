import { FC, useEffect, useMemo, useState } from "react";
import { StoreShipmentInterface } from "../../interfaces/Dtos";
import ShipmentItem from "./ShipmentItem";
import PaginationFooter from "../../components/PaginationFooter";

interface ShipmentTableProps {
  shipments: StoreShipmentInterface[];
}

const ROWS_PER_PAGE = 5;

const ShipmentTable: FC<ShipmentTableProps> = ({ shipments }) => {
  const [itemOffset, setItemOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const paginatedShipments = useMemo(() => {
    return shipments.slice(itemOffset, itemOffset + ROWS_PER_PAGE);
  }, [shipments, itemOffset]);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset =
      (event.selected * ROWS_PER_PAGE) % shipments.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    setTotalPages(Math.ceil(shipments.length / ROWS_PER_PAGE));
  }, [itemOffset, shipments]);

  return (
    <div>
      <div className="overflow-x-auto">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="text-left px-4 py-2 font-semibold text-xs">GUÍA</th>

              <th className="text-left px-4 py-2 font-semibold text-xs">
                REMITENTE
              </th>

              <th className="text-left px-4 py-2 font-semibold text-xs">
                DESTINATARIO
              </th>

              <th className="text-left px-4 py-2 font-semibold text-xs">
                CARACTERÍSTICAS
              </th>

              <th className="text-left px-4 py-2 font-semibold text-xs">FACTURA</th>

              <th className="text-left px-4 py-2 font-semibold text-xs">
                UBICACIÓN
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedShipments.map((shipment, index: number) => (
              <ShipmentItem key={index} index={index} shipment={shipment} />
            ))}
          </tbody>
        </table>
      </div>

      <PaginationFooter
        totalPages={totalPages}
        itemsOffSet={itemOffset}
        rowCounts={ROWS_PER_PAGE}
        totalItems={shipments.length}
        handlePageClick={handlePageClick}
      />
    </div>
  );
};

export default ShipmentTable;
