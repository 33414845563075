import React, {
  FC,
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from "react";
import classNames from "classnames";
import FormLabel from "./FormLabel";
import { FormikErrors } from "formik";
import LoadingIcon from "./LodingIcon";
import { RadioGroup, Combobox } from "@headlessui/react";
import Datepicker, { DatepickerType } from "react-tailwindcss-datepicker";
import {
  CloudArrowUpIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import {
  Switch as HeadlessSwitch,
  SwitchProps as HeadlessSwitchProps,
} from "@headlessui/react";

const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
const inputClassName =
  "focus block w-full rounded-xl border-0 text-gray-400 " +
  "text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 " +
  "placeholder:text-gray-400 focus:ring-2 focus:ring-inset " +
  "focus:ring-indigo-600 sm:text-sm sm:leading-6 " +
  "invalid:[&:not(:placeholder-shown):not(:focus)]:ring-red-500 peer " +
  "h-10";

interface FormTextProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  isRequiredLabel?: boolean;
  name?: string;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  className?: string;
  clickeable?: boolean;
  onClick?: () => void;
  ref?: React.Ref<HTMLInputElement>;
}
export const FormTextInput: FC<FormTextProps> = ({
  label,
  type,
  isRequiredLabel,
  name,
  error,
  className,
  disabled,
  clickeable,
  onClick,
  ref,
  ...props
}) => {
  const [currentType, setCurrentType] = useState(type);

  return (
    <>
      {!!label && (
        <FormLabel
          fieldName={label}
          htmlFor={props.id}
          isRequired={isRequiredLabel}
        />
      )}
      <div className="relative flex flex-1 items-center">
        <div
          onClick={onClick}
          className={classNames(
            "absolute rounded-r-xl cursor-pointer flex items-center justify-center right-0 top-0 bottom-0 bg-indigo-600 hover:opacity-75 w-16",
            !clickeable && "hidden"
          )}
        >
          <span className="font-semibold text-white">Buscar</span>
        </div>

        <input
          {...props}
          ref={ref}
          name={name}
          type={currentType}
          disabled={disabled}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClick?.();
            }
          }}
          className={classNames(
            inputClassName,
            "truncate",
            !!error && "ring-red-500",
            disabled && "bg-gray-100 text-gray-500",
            clickeable && "pr-20",
            className
          )}
        />
        {type === "password" && currentType === "password" && (
          <EyeIcon
            onClick={() => setCurrentType("text")}
            className="absolute cursor-pointer text-indigo-600 h-5 w-5 right-2"
          />
        )}
        {type === "password" && currentType === "text" && (
          <EyeSlashIcon
            onClick={() => setCurrentType("password")}
            className="absolute cursor-pointer text-indigo-600 h-5 w-5 right-2"
          />
        )}
      </div>
      {typeof error === "string" && !!error && (
        <span className="mt-2 text-sm text-red-500">{error}</span>
      )}
      {Array.isArray(error) && (
        <span className="mt-2 text-sm text-red-500">{error.join(", ")}</span>
      )}
    </>
  );
};

interface FormTextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  isRequiredLabel?: boolean;
  name?: string;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  className?: string;
}
export const FormTextAreaInput: FC<FormTextAreaProps> = ({
  label,
  isRequiredLabel,
  name,
  error,
  className,
  disabled,
  ...props
}) => {
  return (
    <div>
      {!!label && (
        <FormLabel
          fieldName={label}
          htmlFor={props.id}
          isRequired={isRequiredLabel}
        />
      )}
      <textarea
        {...props}
        name={name}
        disabled={disabled}
        className={classNames(
          inputClassName,
          "h-auto",
          !!error && "ring-red-500",
          disabled && "bg-gray-100 text-gray-500",
          className
        )}
        style={{ minHeight: "2.5rem" }}
      />
      {typeof error === "string" && !!error && (
        <span className="mt-2 text-sm text-red-500">{error}</span>
      )}
      {Array.isArray(error) && (
        <span className="mt-2 text-sm text-red-500">{error.join(", ")}</span>
      )}
    </div>
  );
};

interface FormSelectProps<T>
  extends React.InputHTMLAttributes<HTMLInputElement> {
  selected?: T;
  options: T[];
  label?: string;
  name: string;
  notFound?: string;
  disabled?: boolean;
  placeholder?: string;
  isRequiredLabel?: boolean;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  optionString: (opt: T) => string;
  onSelectOption?: (opt: T) => void;
  onSearch?: (opt: T, search: string) => boolean;
  RenderOption?: ({ option }: { option: T }) => JSX.Element;
  containerClassName?: string;
}
export const FormSelect = <T extends any>({
  selected,
  options,
  label,
  name,
  error,
  isRequiredLabel,
  disabled = false,
  placeholder = "Buscar...",
  notFound = "Elementos no encontrados",
  optionString,
  onSearch,
  onSelectOption = () => {},
  RenderOption = ({ option }) => <p>{optionString(option)}</p>,
  containerClassName,
  ...props
}: FormSelectProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const [focused, setFocused] = useState(false);
  const [displayBottom, setDisplayBottom] = useState(true);

  const value = useMemo(() => {
    return selected ? optionString(selected) : undefined;
  }, [selected, optionString]);

  const searchFunction = useCallback(
    (option: T, search: string) => {
      if (!!onSearch) return onSearch(option, search);

      return optionString(option)
        .toLowerCase()
        .split(" ")
        .some((s) => s.startsWith(search));
    },
    [onSearch, optionString]
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setFocused]);

  useEffect(() => {
    const dropdown = document.getElementById(`form-select-${name}`);

    if (!dropdown) return;

    const triggerRect = dropdown.getBoundingClientRect();
    const dropdownHeight = dropdown.offsetHeight;

    let dropdownTop = triggerRect.top;

    if (dropdownTop + dropdownHeight + 100 > window.innerHeight) {
      setDisplayBottom(false);
      dropdownTop = triggerRect.top - dropdownHeight;
      dropdown.style.marginTop = `calc(-${dropdownHeight}px - 2.5rem)`;
    } else {
      setDisplayBottom(true);
      dropdown.style.marginTop = "0";
    }
  }, [focused, name]);

  useEffect(() => {
    let search: string = ""; // Search string

    const handleKeyDown = (event: KeyboardEvent) => {
      if (!focused) return;

      let index = -1;
      const key = event.key.toLowerCase();

      if (key.length === 1) {
        search += key;
        index = options.findIndex((option) => searchFunction(option, search));

        if (index === -1 && search.length > 1) {
          search = key;
          index = options.findIndex((option) => searchFunction(option, search));
        }
      }

      if (index !== -1) {
        const element = document.getElementById(
          `form-select-${name}-option-${index}`
        );
        element?.focus();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [focused, options, name, optionString, searchFunction]);

  return (
    <div ref={ref} className="flex flex-1 flex-col">
      {!!label && (
        <FormLabel
          fieldName={label}
          htmlFor={props.id}
          isRequired={isRequiredLabel}
        />
      )}

      <div
        className={classNames(
          "divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-sm ring-1 ring-black ring-opacity-5",
          focused && (displayBottom ? "rounded-b-none" : "rounded-t-none"),
          containerClassName
        )}
      >
        <Combobox
          onChange={(option: T) => {
            onSelectOption(option);
            setFocused(false);
          }}
        >
          <div className="relative items-center cursor-pointer">
            {focused ? (
              <ChevronUpIcon
                className="pointer-events-none absolute right-4 top-2.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            ) : (
              <ChevronDownIcon
                className="pointer-events-none absolute right-4 top-2.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            )}

            <div
              {...props}
              onChange={() => {}}
              id={name}
              placeholder={disabled ? "" : placeholder}
              onClick={() => setFocused((f) => !f)}
              className={classNames(
                focused && "ring-2 ring-inset ring-indigo-600",
                inputClassName,
                "w-full pr-11 pl-4 text-gray-400 ring-0 flex items-center truncate",
                !!error && "ring-red-500",
                focused &&
                  (displayBottom ? "rounded-b-none" : "rounded-t-none"),
                disabled && "bg-gray-100 text-gray-500 pointer-events-none",
                containerClassName
              )}
            >
              {value ?? (
                <p className="text-gray-400 text-sm truncate">{placeholder}</p>
              )}
            </div>
          </div>

          <div
            id={`form-select-${name}`}
            className={classNames(
              "absolute z-40 divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-black ring-opacity-5",
              !focused && "hidden",
              displayBottom ? "rounded-b-xl" : "rounded-t-xl"
            )}
          >
            {options.length > 0 && focused && (
              <Combobox.Options
                static
                style={{ width: `${ref.current?.clientWidth}px` }}
                className="max-h-64 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
              >
                {options.map((option, index) => (
                  <Combobox.Option
                    key={index}
                    id={`form-select-${name}-option-${index}`}
                    value={option}
                    className={({ active }) =>
                      classNames(
                        "cursor-pointer px-4 py-2",
                        active && "bg-indigo-600 text-white"
                      )
                    }
                  >
                    <RenderOption option={option} />
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            )}

            {focused && options.length === 0 && (
              <p
                className="p-4 text-sm text-gray-500"
                style={{ width: `${ref.current?.clientWidth}px` }}
              >
                {notFound}
              </p>
            )}
          </div>
        </Combobox>
      </div>

      {typeof error === "string" && !!error && (
        <span className="mt-2 text-sm text-red-500">{error}</span>
      )}
      {Array.isArray(error) && (
        <span className="mt-2 text-sm text-red-500">{error.join(", ")}</span>
      )}
    </div>
  );
};

interface FormSelectOption<T> {
  name: string;
  value: T;
  disabled?: boolean;
  tooltip?: string;
}
interface FormSelectOptionObjectsProps<T>
  extends React.InputHTMLAttributes<HTMLInputElement> {
  selected?: T;
  options: FormSelectOption<T>[];
  label?: string;
  name: string;
  notFound?: string;
  disabled?: boolean;
  placeholder?: string;
  isRequiredLabel?: boolean;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  onSelectOption?: (opt: T) => void;
  onSearch?: (opt: { name: string; value: T }, search: string) => boolean;
  RenderOption?: ({
    option,
  }: {
    option: { name: string; value: T };
  }) => JSX.Element;
}
export const FormSelectOptionObjects = <T extends any>({
  selected,
  options,
  label,
  name,
  error,
  isRequiredLabel,
  disabled = false,
  placeholder = "Buscar...",
  notFound = "Elementos no encontrados",
  //optionString,
  onSearch,
  onSelectOption = () => {},
  RenderOption = ({ option }) => <p>{option.name}</p>,
  ...props
}: FormSelectOptionObjectsProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const [focused, setFocused] = useState(false);
  const [displayBottom, setDisplayBottom] = useState(true);

  const value = useMemo(() => {
    return !!selected
      ? options.find((opt) => opt.value === selected)?.name
      : undefined;
  }, [selected, options]);

  const searchFunction = useCallback(
    (option: { name: string; value: T }, search: string) => {
      if (!!onSearch) return onSearch(option, search);
    },
    [onSearch]
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setFocused]);

  useEffect(() => {
    const dropdown = document.getElementById(`form-select-${name}`);

    if (!dropdown) return;

    const triggerRect = dropdown.getBoundingClientRect();
    const dropdownHeight = dropdown.offsetHeight;

    let dropdownTop = triggerRect.top;

    if (dropdownTop + dropdownHeight + 100 > window.innerHeight) {
      setDisplayBottom(false);
      dropdownTop = triggerRect.top - dropdownHeight;
      dropdown.style.marginTop = `calc(-${dropdownHeight}px - 2.5rem)`;
    } else {
      setDisplayBottom(true);
      dropdown.style.marginTop = "0";
    }
  }, [focused, name]);

  useEffect(() => {
    let search: string = ""; // Search string

    const handleKeyDown = (event: KeyboardEvent) => {
      if (!focused) return;

      let index = -1;
      const key = event.key.toLowerCase();

      if (key.length === 1) {
        search += key;
        index = options.findIndex((option) => searchFunction(option, search));

        if (index === -1 && search.length > 1) {
          search = key;
          index = options.findIndex((option) => searchFunction(option, search));
        }
      }

      if (index !== -1) {
        const element = document.getElementById(
          `form-select-${name}-option-${index}`
        );
        element?.focus();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [focused, options, name, searchFunction]);

  return (
    <div ref={ref} className="flex flex-1 flex-col">
      {!!label && (
        <FormLabel
          fieldName={label}
          htmlFor={props.id}
          isRequired={isRequiredLabel}
        />
      )}

      <div
        className={classNames(
          "divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-sm ring-1 ring-black ring-opacity-5",
          focused && (displayBottom ? "rounded-b-none" : "rounded-t-none")
        )}
      >
        <Combobox
          onChange={(option: T) => {
            onSelectOption(option);
            setFocused(false);
          }}
        >
          <div className="relative items-center cursor-pointer">
            {focused ? (
              <ChevronUpIcon
                className="pointer-events-none absolute right-4 top-2.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            ) : (
              <ChevronDownIcon
                className="pointer-events-none absolute right-4 top-2.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            )}

            <div
              {...props}
              onChange={() => {}}
              id={name}
              placeholder={disabled ? "" : placeholder}
              onClick={() => setFocused((f) => !f)}
              className={classNames(
                focused && "ring-2 ring-inset ring-indigo-600",
                inputClassName,
                "w-full pr-11 pl-4 text-gray-400 ring-0 flex items-center truncate",
                !!error && "ring-red-500",
                focused &&
                  (displayBottom ? "rounded-b-none" : "rounded-t-none"),
                disabled && "bg-gray-100 text-gray-500 pointer-events-none"
              )}
            >
              <p className="text-sm leading-5 text-sm truncate">
                {!!value ? value : placeholder}
              </p>
            </div>
          </div>

          <div
            id={`form-select-${name}`}
            className={classNames(
              "absolute z-40 divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-black ring-opacity-5",
              !focused && "hidden",
              displayBottom ? "rounded-b-xl" : "rounded-t-xl"
            )}
          >
            {options.length > 0 && focused && (
              <Combobox.Options
                static
                style={{ width: `${ref.current?.clientWidth}px` }}
                className="max-h-64 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
              >
                {options.map((option, index) => (
                  <div data-te-toggle="tooltip" title={option.tooltip}>
                    <Combobox.Option
                      key={index}
                      disabled={option.disabled}
                      id={`form-select-${name}-option-${index}`}
                      value={option.value}
                      className={({ active }) =>
                        classNames(
                          !option.disabled && "cursor-pointer",
                          !!option.disabled && "text-gray-400 bg-gray-100",
                          "px-4 py-2",
                          active && "bg-indigo-600 text-white"
                        )
                      }
                    >
                      <RenderOption option={option} />
                    </Combobox.Option>
                  </div>
                ))}
              </Combobox.Options>
            )}

            {focused && options.length === 0 && (
              <p
                className="p-4 text-sm text-gray-500"
                style={{ width: `${ref.current?.clientWidth}px` }}
              >
                {notFound}
              </p>
            )}
          </div>
        </Combobox>
      </div>

      {typeof error === "string" && !!error && (
        <span className="mt-2 text-sm text-red-500">{error}</span>
      )}
      {Array.isArray(error) && (
        <span className="mt-2 text-sm text-red-500">{error.join(", ")}</span>
      )}
    </div>
  );
};

interface FormRadioGroupProps<T>
  extends React.InputHTMLAttributes<HTMLInputElement> {
  selected?: T;
  options: T[];
  name: string;
  n?: number;
  label?: string;
  isRequiredLabel?: boolean;
  error?: string;
  className?: string;
  onSelectOption: (opt: T) => void;
  optionString: (opt: T) => string;
}
export const FormRadioGroup = <T extends any>({
  disabled,
  selected,
  options,
  name,
  label,
  isRequiredLabel,
  n = options.length,
  error,
  className,
  onSelectOption,
  optionString,
  ...props
}: FormRadioGroupProps<T>) => {
  return (
    <>
      <RadioGroup
        disabled={disabled}
        value={selected}
        onChange={(option: T) => onSelectOption(option)}
        className={classNames(className)}
      >
        {!!label && (
          <FormLabel fieldName={label} isRequired={isRequiredLabel} />
        )}

        <div
          className={`grid grid-cols-${n} gap-3 mt-1`}
          style={{ gridTemplateColumns: `repeat(${n}, minmax(0, 1fr))` }}
        >
          {options.map((option, index) => (
            <RadioGroup.Option
              key={index}
              value={option}
              disabled={disabled}
              className={({ active, checked }) =>
                classNames(
                  className,
                  "focus:outline-none",
                  active ? "ring-2 ring-indigo-600 ring-offset-2" : "",
                  checked
                    ? "!bg-indigo-600 text-white hover:!bg-indigo-500"
                    : "ring-1 ring-inset ring-gray-300 text-gray-900",
                  disabled
                    ? "bg-gray-100"
                    : "bg-white hover:bg-gray-50 cursor-pointer",
                  "items-center rounded-full text-center py-3 text-sm font-semibold uppercase sm:flex-1"
                )
              }
            >
              <RadioGroup.Label as="span">
                {optionString(option)}
              </RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      {!!error && <span className="mt-2 text-sm text-red-500">{error}</span>}
    </>
  );
};

interface FormSimpleRadioGroupOption<T> {
  name: string;
  value: T;
  disabled?: boolean;
  tooltip?: string;
}
interface FormSimpleRadioGroupProps<T> extends FormTextProps {
  options: FormSimpleRadioGroupOption<T>[];
  selected?: T;
  disabled?: boolean;
  onSelectOption: (opt: T) => void;
  description?: string;
  horizontal?: boolean;
  optionsClassName?: string;
  wrapperClassName?: string;
}
export const FormSimpleRadioGroup = <T extends any>({
  label,
  name,
  selected,
  disabled,
  onSelectOption,
  options,
  className,
  isRequiredLabel,
  description = "",
  horizontal = false,
  optionsClassName,
  wrapperClassName,
  ...props
}: FormSimpleRadioGroupProps<T>) => {
  const Options = useMemo(() => {
    return options.map((option, index) => (
      <div
        key={index}
        className="flex items-center"
        title={option.tooltip}
        data-te-toggle="tooltip"
      >
        <input
          id={`option-${name}-${option.name}-${index}`}
          name={`simple-radio-group-${name}-${index}`}
          type="radio"
          disabled={disabled || option.disabled}
          checked={option.value === selected}
          onChange={() =>
            !disabled && !option.disabled && onSelectOption(option.value)
          }
          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
        />
        <label
          className={classNames(
            "ml-3 block text-sm font-medium leading-6 truncate",
            (disabled || option.disabled) && "text-gray-400",
            optionsClassName
          )}
        >
          {option.name}
        </label>
      </div>
    ));
  }, [options, name, disabled, selected, optionsClassName, onSelectOption]);

  return (
    <div className={className} {...props}>
      {!!label && (
        <FormLabel
          fieldName={label}
          htmlFor={props.id}
          isRequired={isRequiredLabel}
        />
      )}
      <p className="text-m text-gray-500">{description}</p>
      <fieldset>
        <div
          className={classNames(
            "flex gap-4 flex-wrap",
            horizontal ? "flex-row items-center" : "flex-col",
            wrapperClassName
          )}
        >
          {Options}
        </div>
      </fieldset>
    </div>
  );
};

export interface MultipleRadioOption<T> {
  name: string;
  checked: boolean;
  value: T;
}
interface FormMultipleRadioGroupProps<T> extends FormTextProps {
  options: MultipleRadioOption<T>[];
  disabled?: boolean;
  description?: string;
  horizontal?: boolean;
  n?: number;
  setOptions: React.Dispatch<React.SetStateAction<MultipleRadioOption<T>[]>>;
}
export const FormMultipleRadioGroup = <T extends any>({
  label,
  name,
  disabled,
  options,
  error,
  className,
  isRequiredLabel,
  n = options.length,
  description = "",
  horizontal = false,
  setOptions,
  ...props
}: FormMultipleRadioGroupProps<T>) => {
  return (
    <>
      <RadioGroup>
        {!!label && (
          <FormLabel fieldName={label} isRequired={isRequiredLabel} />
        )}

        <div
          className={`grid grid-cols-${n} gap-3 mt-1`}
          style={{ gridTemplateColumns: `repeat(${n}, minmax(0, 1fr))` }}
        >
          {options.map((option, index) => (
            <RadioGroup.Option
              key={index}
              value={option}
              onClick={() =>
                setOptions((options) => {
                  const newOptions = [...options];
                  newOptions[index] = {
                    ...newOptions[index],
                    checked: !newOptions[index].checked,
                  };
                  return newOptions;
                })
              }
              className={({ active }) =>
                classNames(
                  className,
                  "cursor-pointer focus:outline-none px-3",
                  active ? "ring-2 ring-indigo-600 ring-offset-2" : "",
                  option.checked
                    ? "bg-indigo-600 text-white hover:bg-indigo-500"
                    : "ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50",
                  "items-center rounded-full text-center py-2 text-sm font-semibold sm:flex-1"
                )
              }
            >
              <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>

      {typeof error === "string" && !!error && (
        <span className="mt-2 text-sm text-red-500">{error}</span>
      )}
      {Array.isArray(error) && (
        <span className="mt-2 text-sm text-red-500">{error.join(", ")}</span>
      )}
    </>
  );
};

interface FormMultipleSimpleRadioGroupProps<T> extends FormTextProps {
  options: MultipleRadioOption<T>[];
  disabled?: boolean;
  description?: string;
  horizontal?: boolean;
  optionsClassName?: string;
  wrapperClassName?: string;
  setOptions: React.Dispatch<React.SetStateAction<MultipleRadioOption<T>[]>>;
}
export const FormMultipleSimpleRadioGroup = <T extends any>({
  label,
  name,
  disabled,
  options,
  className,
  isRequiredLabel,
  description = "",
  horizontal = false,
  optionsClassName,
  wrapperClassName,
  setOptions,
  ...props
}: FormMultipleSimpleRadioGroupProps<T>) => {
  const Options = useMemo(() => {
    return options.map((option, index) => (
      <div
        key={index}
        className="flex items-center cursor-pointer"
        onClick={() =>
          setOptions((options) => {
            const newOptions = [...options];
            newOptions[index] = {
              ...newOptions[index],
              checked: !newOptions[index].checked,
            };
            return newOptions;
          })
        }
      >
        <input
          id={`option-${option.name}-${index}`}
          name={`multiple-simple-radio-group-${name}-${index}`}
          type="checkbox"
          disabled={disabled}
          checked={option.checked}
          onChange={() => {}}
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
        />
        <label
          className={classNames(
            "ml-3 block text-sm font-medium leading-6 truncate cursor-pointer",
            disabled && "text-gray-400",
            optionsClassName
          )}
        >
          {option.name}
        </label>
      </div>
    ));
  }, [options, name, disabled, optionsClassName, setOptions]);

  return (
    <div className={className} {...props}>
      {!!label && <FormLabel fieldName={label} isRequired={isRequiredLabel} />}
      <p className="text-m text-gray-500">{description}</p>
      <fieldset>
        <div
          className={classNames(
            "flex gap-4 flex-wrap",
            horizontal ? "flex-row items-center" : "flex-col",
            wrapperClassName
          )}
        >
          {Options}
        </div>
      </fieldset>
    </div>
  );
};

interface SearchProps<T> extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string;
  label?: string;
  name?: string;
  disabled?: boolean;
  placeholder?: string;
  notFound?: string;
  isRequiredLabel?: boolean;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeFocus?: (focus: boolean) => void;
  onSelectOption?: (opt: T) => void;
  filter?: (opt: T, value: string) => boolean;
  onClick?: () => void;
  RenderOption: ({ option }: { option: T }) => JSX.Element;
  LastOption?: () => JSX.Element;
}
interface FormSearchProps<T> extends SearchProps<T> {
  options: T[];
  loader?: boolean;
  clickeable?: boolean;
}
export const FormSearch = <T extends any>({
  value,
  label,
  name,
  options,
  error,
  isRequiredLabel,
  disabled = false,
  loader = false,
  clickeable = false,
  placeholder = "Buscar...",
  notFound = "Elementos no encontrados",
  onChange = () => {},
  onClick = () => {},
  onSelectOption = () => {},
  filter = () => true,
  onChangeFocus = () => {},
  RenderOption,
  LastOption = undefined,
  ...props
}: FormSearchProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const [focused, setFocused] = useState(false);
  const [searching, setSearching] = useState(false);

  const filteredOptions = useMemo(() => {
    return options.filter((option) => filter(option, value));
  }, [options, value, filter]);

  const displayDropdown = useMemo(() => {
    const show = focused && (!clickeable || searching);

    return (
      (filteredOptions.length > 0 && show) ||
      (show && value !== "" && filteredOptions.length === 0) ||
      (value !== "" && show && !!LastOption)
    );
  }, [filteredOptions, value, focused, LastOption]);

  const Options = useMemo(() => {
    const show = focused && (!clickeable || searching);

    return (
      <div
        className={classNames(
          "w-full absolute z-40 divide-y divide-gray-100 overflow-hidden rounded-b-xl bg-white shadow-sm ring-1 ring-black ring-opacity-5",
          !displayDropdown && "hidden"
        )}
      >
        {loader && (
          <div className="flex flex-1 items-center p-4">
            <LoadingIcon size="2rem" />
          </div>
        )}

        {!loader && show && filteredOptions.length > 0 && (
          <Combobox.Options
            static
            className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
          >
            {filteredOptions.map((option, index) => (
              <Combobox.Option
                key={index}
                value={option}
                className={({ active }) =>
                  classNames(
                    "group cursor-pointer px-4 py-2",
                    active && "bg-indigo-600 !text-white"
                  )
                }
              >
                <RenderOption option={option} />
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}

        {!loader && show && value !== "" && filteredOptions.length === 0 && (
          <p className="p-4 text-sm text-gray-500">{notFound}</p>
        )}

        {show && !!LastOption && (
          <Combobox.Options
            static
            className="max-h-72 scroll-py-2 overflow-y-auto text-sm text-gray-800"
          >
            <Combobox.Option
              value={undefined}
              className={({ active }) =>
                classNames(
                  "cursor-pointer px-4",
                  active && "bg-indigo-600 text-white"
                )
              }
            >
              <LastOption />
            </Combobox.Option>
          </Combobox.Options>
        )}
      </div>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loader,
    focused,
    searching,
    clickeable,
    filteredOptions,
    value,
    displayDropdown,
  ]);

  const handleClick = () => {
    if (clickeable) {
      onClick();
      setSearching(true);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setFocused(false);
        setSearching(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setFocused]);

  useEffect(() => {
    onChangeFocus(focused);
  }, [focused, onChangeFocus]);

  return (
    <div>
      {!!label && (
        <FormLabel
          fieldName={label}
          htmlFor={props.id}
          isRequired={isRequiredLabel}
        />
      )}

      <div
        className={classNames(
          "divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-sm ring-1 ring-black ring-opacity-5",
          displayDropdown && "rounded-b-none"
        )}
        ref={ref}
      >
        <Combobox
          onChange={(option: T) => {
            onSelectOption(option);
            setFocused(false);
            setSearching(false);
          }}
        >
          <div className="relative flex flex-1 flex-col items-center">
            <MagnifyingGlassIcon
              className={classNames(
                "pointer-events-none absolute left-4 top-2.5 h-5 w-5 text-gray-400",
                clickeable && "hidden"
              )}
              aria-hidden="true"
            />

            <div
              onClick={handleClick}
              className={classNames(
                "absolute rounded-r-xl cursor-pointer flex items-center justify-center right-0 top-0 bottom-0 bg-indigo-600 hover:opacity-75 w-16",
                !clickeable && "hidden",
                displayDropdown && "!rounded-b-none"
              )}
            >
              <span className="font-semibold text-white">Buscar</span>
            </div>

            <input
              {...props}
              autoComplete="off"
              value={value}
              disabled={disabled}
              placeholder={disabled ? "" : placeholder}
              onChange={onChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleClick();
                }
              }}
              onFocus={() => setFocused(true)}
              className={classNames(
                inputClassName,
                "w-full placeholder:text-gray-400 ring-0 focus:ring-0",
                displayDropdown && "rounded-b-none",
                disabled && "bg-gray-100 text-gray-500",
                clickeable ? "pr-20 pl-4" : "pl-14 pr-4",
                !!error && "ring-red-500"
              )}
            />
          </div>

          {Options}
        </Combobox>
      </div>

      {typeof error === "string" && !!error && (
        <span className="mt-2 text-sm text-red-500">{error}</span>
      )}
      {Array.isArray(error) && (
        <span className="mt-2 text-sm text-red-500">{error.join(", ")}</span>
      )}
    </div>
  );
};

interface FormGroupSearchProps<T> extends SearchProps<T> {
  optionGroups: { title: string; loader?: boolean; options: T[] }[];
  clickeable?: boolean;
}
export const FormGroupSearch = <T extends any>({
  value,
  optionGroups,
  label,
  name,
  error,
  isRequiredLabel,
  disabled = false,
  clickeable = false,
  placeholder = "Buscar...",
  notFound = "Elementos no encontrados",
  onChange,
  onClick = () => {},
  onSelectOption = () => {},
  onChangeFocus = () => {},
  filter = () => true,
  RenderOption,
  LastOption = undefined,
  ...props
}: FormGroupSearchProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const [focused, setFocused] = useState(false);
  const [searching, setSearching] = useState(false);

  const filteredOptionGroups = useMemo(() => {
    return optionGroups
      .map((options) => ({
        title: options.title,
        loader: options.loader,
        options: options.options.filter((option) => filter(option, value)),
      }))
      .filter(
        (optionGroup) => !!optionGroup.loader || optionGroup.options.length > 0
      );
  }, [optionGroups, value, filter]);

  const displayDropdown = useMemo(() => {
    const show = focused && (!clickeable || searching);

    return (
      (filteredOptionGroups.length > 0 && show) ||
      (show && value !== "" && filteredOptionGroups.length === 0) ||
      (value !== "" && show && !!LastOption)
    );
  }, [filteredOptionGroups, value, focused, LastOption]);

  const handleClick = () => {
    if (clickeable) {
      onClick();
      setSearching(true);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setFocused(false);
        setSearching(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setFocused]);

  useEffect(() => {
    onChangeFocus(focused);
  }, [focused, onChangeFocus]);

  return (
    <div ref={ref}>
      {!!label && (
        <FormLabel
          fieldName={label}
          htmlFor={props.id}
          isRequired={isRequiredLabel}
        />
      )}

      <div
        className={classNames(
          "divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-sm ring-1 ring-black ring-opacity-5",
          displayDropdown && "rounded-b-none"
        )}
      >
        <Combobox
          onChange={(option: T) => {
            onSelectOption(option);
            setFocused(false);
            setSearching(false);
          }}
        >
          <div className="relative flex flex-1 flex-col items-center">
            <MagnifyingGlassIcon
              className={classNames(
                "pointer-events-none absolute left-4 top-2.5 h-5 w-5 text-gray-400",
                clickeable && "hidden"
              )}
              aria-hidden="true"
            />

            <div
              onClick={handleClick}
              className={classNames(
                "absolute rounded-r-xl cursor-pointer flex items-center justify-center right-0 top-0 bottom-0 bg-indigo-600 hover:opacity-75 w-16",
                !clickeable && "hidden",
                displayDropdown && "!rounded-b-none"
              )}
            >
              <span className="font-semibold text-white">Buscar</span>
            </div>

            <input
              {...props}
              value={value}
              disabled={disabled}
              placeholder={disabled ? "" : placeholder}
              onChange={onChange}
              onFocus={() => setFocused(true)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleClick();
                }
              }}
              className={classNames(
                inputClassName,
                "w-full placeholder:text-gray-400 ring-0 focus:ring-0",
                displayDropdown && "rounded-b-none",
                disabled && "bg-gray-100 text-gray-500",
                clickeable ? "pr-20 pl-4" : "pl-14 pr-4",
                !!error && "ring-red-500"
              )}
            />
          </div>

          <div
            className={classNames(
              "w-full absolute z-40 divide-y divide-gray-100 overflow-hidden rounded-b-xl bg-white shadow-sm ring-1 ring-black ring-opacity-5",
              !displayDropdown && "hidden"
            )}
          >
            <div className="max-h-80 scroll-py-2 overflow-y-auto overflow-x-hidden ">
              {filteredOptionGroups.length > 0 &&
                filteredOptionGroups.map((optionGroup, index) => (
                  <React.Fragment key={index}>
                    <h2 className="bg-gray-100 px-4 py-4 text-xs font-semibold text-gray-900">
                      {optionGroup.title}
                    </h2>

                    <Combobox.Options
                      static
                      className="py-2 text-sm text-gray-800"
                    >
                      {!optionGroup.loader &&
                        optionGroup.options.map((option, index) => (
                          <Combobox.Option
                            key={index}
                            value={option}
                            className={({ active }) =>
                              classNames(
                                "cursor-pointer px-4 py-2 group",
                                active && "bg-indigo-600 text-white"
                              )
                            }
                          >
                            <RenderOption option={option} />
                          </Combobox.Option>
                        ))}

                      {!!optionGroup.loader && (
                        <div className="flex flex-1 items-center p-4">
                          <LoadingIcon size="2rem" />
                        </div>
                      )}
                    </Combobox.Options>
                  </React.Fragment>
                ))}

              {focused &&
                (!clickeable || searching) &&
                value !== "" &&
                filteredOptionGroups.length === 0 && (
                  <p className="p-4 text-sm text-gray-500">{notFound}</p>
                )}
            </div>

            {!!LastOption && focused && (!clickeable || searching) && (
              <Combobox.Options
                static
                className="max-h-72 scroll-py-2 text-sm text-gray-800"
              >
                <Combobox.Option
                  value={null}
                  className={({ active }) =>
                    classNames(
                      "cursor-pointer px-4",
                      active && "bg-indigo-600 text-white"
                    )
                  }
                >
                  <LastOption />
                </Combobox.Option>
              </Combobox.Options>
            )}
          </div>
        </Combobox>
      </div>

      {typeof error === "string" && !!error && (
        <span className="mt-2 text-sm text-red-500">{error}</span>
      )}
      {Array.isArray(error) && (
        <span className="mt-2 text-sm text-red-500">{error.join(", ")}</span>
      )}
    </div>
  );
};

interface SwitchProps extends HeadlessSwitchProps<"button"> {
  label?: string;
  horizontal?: boolean;
}
export const Switch: FC<SwitchProps> = ({
  checked,
  label,
  horizontal,
  ...props
}) => {
  return (
    <div
      className={classNames(
        "flex flex-1",
        horizontal ? "flex-row" : "flex-col"
      )}
    >
      {!!label && <FormLabel fieldName={label} htmlFor={props.id} />}
      <HeadlessSwitch
        {...props}
        checked={checked}
        className={classNames(
          checked ? "bg-indigo-600" : "bg-gray-200",
          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
        )}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={classNames(
            checked ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        />
      </HeadlessSwitch>
    </div>
  );
};

interface FormFileUploadProps extends FormTextProps {
  /**
   * Description of the file to be uploaded
   */
  description?: string;
  /**
   * Callback function that is called when the user selects a file
   */
  onSelectFile: (file: File[]) => void;
  zoneClassName?: string;
}
export const FormFileUpload: FC<FormFileUploadProps> = ({
  name,
  disabled,
  error,
  description,
  className,
  onSelectFile,
  zoneClassName,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);

  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();

    // Verify that the mouse is not over the input
    const rect = labelRef.current?.getBoundingClientRect();
    if (
      !!rect &&
      e.clientX >= rect.left &&
      e.clientX <= rect.right &&
      e.clientY >= rect.top &&
      e.clientY <= rect.bottom
    ) {
      return;
    }
    setIsDraggingOver(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setIsDraggingOver(false);

    if (!!inputRef.current) {
      inputRef.current.files = e.dataTransfer.files;
      onSelectFile(Array.from(e.dataTransfer.files));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!!e.target.files) {
      onSelectFile(Array.from(e.target.files));
    }
  };

  return (
    <div className="flex flex-col gap-4" style={{ minWidth: "15rem" }}>
      <label
        ref={labelRef}
        htmlFor="dropzone-file"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        className={classNames(
          isDraggingOver ? "bg-gray-200" : "bg-gray-50",
          "flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer hover:bg-gray-100",
          zoneClassName
        )}
      >
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          <CloudArrowUpIcon className="w-12 h-12 mb-4 text-gray-500" />
          <p className="mb-2 text-sm text-gray-500 text-center">
            <span className="font-semibold">Haz click para subir</span> o
            arrastra y suelta tu(s) archivo(s) aquí
          </p>
          <p className="text-xs text-gray-500 text-center mx-2">
            {description}
          </p>
        </div>
      </label>

      <div>
        <input
          {...props}
          type="file"
          id="dropzone-file"
          name={name}
          ref={inputRef}
          disabled={disabled}
          onChange={handleChange}
          className={classNames(
            inputClassName,
            !!error && "ring-red-500",
            disabled && "bg-gray-100 text-gray-500",
            className
          )}
        />
        {typeof error === "string" && !!error && (
          <span className="mt-2 text-sm text-red-500">{error}</span>
        )}
        {Array.isArray(error) && (
          <span className="mt-2 text-sm text-red-500">{error.join(", ")}</span>
        )}
      </div>
    </div>
  );
};

interface FormDatePickerProps extends DatepickerType {
  id: string;
  name: string;
  label?: string;
  isRequiredLabel?: boolean;
  className?: string;
  labelClassname?: string;
  containerClassname?: string;
  labelContainerClassname?: string;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
}
export const FormDatePicker: FC<FormDatePickerProps> = ({
  id,
  label = "",
  isRequiredLabel,
  name,
  error,
  className,
  labelClassname,
  containerClassname,
  labelContainerClassname,
  disabled,
  ...props
}) => {
  return (
    <div className={containerClassname}>
      {!!label && (
        <FormLabel
          fieldName={label}
          htmlFor={id}
          isRequired={isRequiredLabel}
        />
      )}

      <div className="flex flex-1 flex-col">
        <Datepicker
          {...props}
          asSingle
          readOnly
          showShortcuts
          useRange={false}
          inputId={id}
          inputName={name}
          disabled={disabled}
          inputClassName={classNames(
            inputClassName,
            "!inline-block",
            !!error && "ring-red-500",
            disabled && "bg-gray-100 text-gray-500",
            className
          )}
        />

        {typeof error === "string" && !!error && (
          <span className="mt-2 text-sm text-red-500">{error}</span>
        )}
        {Array.isArray(error) && (
          <span className="mt-2 text-sm text-red-500">{error.join(", ")}</span>
        )}
      </div>
    </div>
  );
};

interface FormCheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  ref?: React.Ref<HTMLInputElement>;
  containerClassname?: string;
  inputClassname?: string;
  labelClassname?: string;
}
export const FormCheckbox: FC<FormCheckboxProps> = ({
  label = "",
  name,
  className,
  labelClassname,
  inputClassname,
  containerClassname,
  ref,
  ...props
}) => {
  return (
    <div className={classNames("flex items-center", containerClassname)}>
      <input
        {...props}
        ref={ref}
        name={name}
        type="checkbox"
        className={classNames(
          "peer h-4 w-4 text-main-500 focus:ring-main-500 border-gray-300 shadow rounded",
          inputClassname
        )}
      />

      <FormLabel
        fieldName={label}
        htmlFor={name}
        fieldNameClassname={classNames("ml-2 text-sm !mb-0", labelClassname)}
      />
    </div>
  );
};
