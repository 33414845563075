import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TaxIdentificationTypeInterface } from "../../interfaces/TaxIdentificationTypeInterface";
import {
  CountryInterface,
  AppDataInterface,
  BusinessUnitInterface,
  PieceCategoryInterface,
} from "../../interfaces";
import {
  CurrencyDto,
  RateItemDto,
  PaymentModeDto,
  ExchangeRateDto,
  BusinessServiceDto,
  RangeDeclareDto,
  RangeDto,
} from "../../interfaces/Dtos";

interface InmutableState {
  appData: AppDataInterface;
  maxDiscount: number;
  businessUnits: BusinessUnitInterface[];
  countries: CountryInterface[];
  pieceCategories: PieceCategoryInterface[];
  taxIdentificationTypes: TaxIdentificationTypeInterface[];
  businessServices: BusinessServiceDto[];
  rateItems: RateItemDto[];
  paymentModes: PaymentModeDto[];
  exchangeRate: ExchangeRateDto;
  currencyList: CurrencyDto[];
  rangeDeclareList: RangeDeclareDto[];
  volumeMin?: RangeDto;
  densityMax?: RangeDto;
  highDeclaredValue?: RangeDto;
}

const initialState: InmutableState = {
  appData: {} as AppDataInterface,
  countries: [],
  businessUnits: [],
  pieceCategories: [],
  taxIdentificationTypes: [],
  maxDiscount: 30,
  businessServices: [],
  rateItems: [],
  paymentModes: [],
  exchangeRate: {} as ExchangeRateDto,
  currencyList: [],
  rangeDeclareList: [],
};

export const inmutableSlice = createSlice({
  name: "inmutable",
  initialState,
  reducers: {
    setInmutableCountries: (
      state,
      action: PayloadAction<CountryInterface[]>
    ) => {
      state.countries = action.payload;
    },
    setInmutableAppData: (state, action: PayloadAction<AppDataInterface>) => {
      state.appData = action.payload;
    },
    setInmutablePieceCategories: (
      state,
      action: PayloadAction<PieceCategoryInterface[]>
    ) => {
      state.pieceCategories = action.payload;
    },
    setInmutableBusinessUnits: (
      state,
      action: PayloadAction<BusinessUnitInterface[]>
    ) => {
      state.businessUnits = action.payload;
    },
    setInmutableTaxIdentificationTypes: (
      state,
      action: PayloadAction<TaxIdentificationTypeInterface[]>
    ) => {
      state.taxIdentificationTypes = action.payload;
    },
    setInmutableBusinessServices: (
      state,
      action: PayloadAction<BusinessServiceDto[]>
    ) => {
      state.businessServices = action.payload;
    },
    setInmutableRateItems: (state, action: PayloadAction<RateItemDto[]>) => {
      state.rateItems = action.payload;
    },
    setInmutablePaymentModes: (
      state,
      action: PayloadAction<PaymentModeDto[]>
    ) => {
      state.paymentModes = action.payload;
    },
    setInmutableExchangeRate: (
      state,
      action: PayloadAction<ExchangeRateDto>
    ) => {
      state.exchangeRate = action.payload;
    },
    setInmutableCurrencyList: (state, action: PayloadAction<CurrencyDto[]>) => {
      state.currencyList = action.payload;
    },
    setInmutableRangeDeclareList: (
      state,
      action: PayloadAction<RangeDeclareDto[]>
    ) => {
      state.rangeDeclareList = action.payload;
    },
    setInmutableVolumeMin: (state, action: PayloadAction<RangeDto>) => {
      state.volumeMin = action.payload;
    },
    setInmutableDensityMax: (state, action: PayloadAction<RangeDto>) => {
      state.densityMax = action.payload;
    },
    setInmutableHighDeclaredValue: (state, action: PayloadAction<RangeDto>) => {
      state.highDeclaredValue = action.payload;
    },
  },
});

export const {
  setInmutableAppData,
  setInmutableRateItems,
  setInmutableCountries,
  setInmutableExchangeRate,
  setInmutablePaymentModes,
  setInmutableCurrencyList,
  setInmutableBusinessUnits,
  setInmutablePieceCategories,
  setInmutableBusinessServices,
  setInmutableTaxIdentificationTypes,
  setInmutableRangeDeclareList,
  setInmutableVolumeMin,
  setInmutableDensityMax,
  setInmutableHighDeclaredValue,
} = inmutableSlice.actions;

export default inmutableSlice.reducer;
